<template>
  <b-card>

    <!-- search input -->
    <b-row class="mb-1">
      <b-col lg="4">
        <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
        />
      </b-col>
      <b-col lg="8" class="d-flex justify-content-end align-items-center">
        <template v-if="$permissionAbility(ROLES_CREATE, permissions)">
          <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
          >
            Create Role
          </b-button>
        </template>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
        styleClass="vgt-table table-custom-style striped"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'name', type: 'asc' }],
        }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: head -->
        <!-- <span v-if="props.column.field === 'head'">
          <template v-if="props.row.head_id != null">
            <b-avatar :src="props.row.head.data.avatar" class="mx-1" />
            <span class="text-nowrap">{{ props.row.head.data.name }}</span>
          </template>
        </span> -->

        <span v-if="props.column.field === 'format_name'">
            <b>{{ props.row?.name }}</b>
          </span>

        <span v-if="props.column.field === 'format_users_count'">
            <b-badge variant="light-primary">{{ props.row?.users_count }}</b-badge>
          </span>

        <template v-if="props?.column?.field === 'format_member_info'">
              <span v-for="(member, index) in props.row.users.data" :key="index">
                <template v-if="index <= 2">
                  <b-avatar :src="member?.avatar === '' ? '/avatar.svg' : member?.avatar" size="26" style="margin: 1px"
                  />
                </template>
              </span>
          <span v-if="props?.row?.users_count > 3">
                + {{ props?.row?.users_count - 3 }} more
              </span>
          <b-button
              v-if="props?.row?.users_count > 0"
              variant="outline-primary"
              class="btn-icon rounded-circle"
              style="margin-left: 5px"
              v-on:click="showAllAddedMemberModal(props?.row?.users?.data)"
          >
            <feather-icon icon="UsersIcon" />
          </b-button>
          <span v-else>N/A</span>
        </template>

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                  />
                </template>

              <template v-if="$permissionAbility(ROLES_SHOW, permissions)">
                <b-dropdown-item v-on:click="onShowPage(props.row.id)">
                  <feather-icon icon="EyeIcon" class="mr-50"/>
                  <span>Access Control</span>
                </b-dropdown-item>
              </template>

                <template v-if="$permissionAbility(ROLES_EDIT, permissions)">
                  <b-dropdown-item v-on:click="onShow(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50"/>
                    <span>Edit</span>
                  </b-dropdown-item>
                </template>

                <template>
                  <b-dropdown-item v-on:click="() => onCopy(props.row)">
                    <feather-icon icon="CopyIcon" class="mr-50"/>
                    <span>Copy</span>
                  </b-dropdown-item>
                </template>

                <template v-if="props.row.admin !== 1 && $permissionAbility(ROLES_DELETE, permissions)">
                  <b-dropdown-item v-on:click="onDelete(props.row)">
                    <feather-icon icon="TrashIcon" class="mr-50"/>
                    <span>Delete</span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </span>
          </span>

        <!-- Column: Common -->
        <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- form -->

    <b-modal
        id="modal-roles-form"
        centered
        :title="modelType == 'editModel' ? 'Edit' : 'Create'"
        hide-footer
        @hidden="hiddenModal"
        no-close-on-backdrop
    >
      <validation-observer ref="rolesValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- name -->
          <b-form-group label="Title" label-for="name" class="required-label">
            <validation-provider #default="{ errors }" name="name" vid="name">
              <b-form-input
                  id="name"
                  type="text"
                  v-model="name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Role"
                  rules="required|max:255"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- priority -->
          <!-- <b-form-group label="Priority" label-for="priority">
            <validation-provider
              #default="{ errors }"
              name="priority"
              vid="priority"
            >
              <b-form-input
                id="priority"
                type="number"
                v-model="priority"
                :state="errors.length > 0 ? false : null"
                placeholder="Priority"
                rules="required|integer"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group> -->

          <!-- loading button -->
          <template v-if="isRoleFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small/>
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- copy form -->
    <b-modal
        id="modal-role-copy-form"
        centered
        :title="`Copy From: ${roleCopyFromName}`"
        hide-footer
        @hidden="roleCopyHiddenModal"
        no-close-on-backdrop
    >
      <validation-observer ref="roleCopyValidation">
        <b-form v-on:submit.prevent="roleCopyFormSubmit">
          <!-- name -->
          <b-form-group label="Name *" label-for="name">
            <validation-provider
                #default="{ errors }"
                name="name"
                vid="name"
                rules="required|max:255"
            >
              <b-form-input
                  id="name"
                  type="text"
                  v-model="roleCopyName"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Role"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isRoleCopySubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small/>
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
        id="modal-all-added-member"
        centered
        title="Assigned Employee"
        hide-footer
        @hidden="hiddenAllAddedMemberModal"
        no-close-on-backdrop
    >
      <ul>
        <li v-for="(member, index) in assingedMember" :key="index">
          <b-avatar :src="member?.avatar === '' ? '/avatar.svg' : member?.avatar" size="26" style="margin: 1px" />
          {{ member.name }}
        </li>
      </ul>
    </b-modal>

  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner, BFormCheckbox, BRow, BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max, integer } from '@validations'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import {
  ROLES_SHOW,
  ROLES_DELETE,
  ROLES_CREATE,
  ROLES_EDIT,
} from '@/helpers/permissionsConstant'
import TaskStatistics from '@/layouts/components/task/TaskStatistics.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import UserSelect from '@/layouts/components/UserSelect.vue'

export default {
  name: 'RolesView',
  components: {
    UserSelect,
    BCol,
    BCardActions,
    BRow,
    TaskStatistics,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ROLES_EDIT,
      ROLES_CREATE,
      ROLES_DELETE,
      ROLES_SHOW,
      assingedMember: [],
      modelType: '',
      roleId: '',
      name: '',
      roleCopyFromName: '',
      roleCopyName: '',
      isRoleCopySubmitLoading: false,
      pageLength: 10,
      columns: [
        {
          label: 'Name',
          field: 'format_name',
        },
        // {
        //   label: 'No. of Users',
        //   field: 'format_users_count',
        //   sortable: false,
        // },
        {
          label: "Assigned Employee",
          field: 'format_member_info',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      delayTimer: null,
      isLoading: false,
      isRoleFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: 'name',
            type: 'desc'
          },
          {
            field: 'created_at',
            type: 'desc'
          },
        ],
        page: 1,
        perPage: 10,
      },
    }
  },

  computed: {
    ...mapGetters({
      permissions: 'userModule/getPermissions',
    }),
  },

  methods: {
    showAllAddedMemberModal(value) {
      this.assingedMember = value.map((item) => {
        let name = `${item?.name} (${item?.email})`;
        return {
          name,
          id: item.id,
          avatar: item?.avatar,
        };
      });

      this.$bvModal.show("modal-all-added-member");
    },

    hiddenAllAddedMemberModal() {
      this.$bvModal.hide("modal-all-added-member");
    },
    onShowPage(id) {
      this.$router.push({
        name: 'admin-roles-permissions',
        params: { id },
      })
    },
    showModal() {
      this.$bvModal.show('modal-roles-form')
    },
    hiddenModal() {
      this.$bvModal.hide('modal-roles-form')
      this.resetModal()
    },
    roleCopyShowModal() {
      this.$bvModal.show('modal-role-copy-form')
    },
    roleCopyHiddenModal() {
      this.$bvModal.hide('modal-role-copy-form')
      this.resetModal()
    },
    resetModal() {
      this.name = ''
      this.roleId = ''
      this.modelType = ''
      this.roleCopyName = ''
    },
    onShow(value) {
      this.modelType = 'editModel'
      this.name = value?.name
      this.roleId = value?.id
      // this.priority = value.priority;

      this.showModal()
    },
    onCopy(value) {
      this.roleCopyFromName = value?.name
      this.roleId = value?.id

      this.roleCopyShowModal()
    },

    async onDelete(row) {
      this.$swal({
        title: 'Warning!',
        text: `Are You Sure You Want To Delete ${row.name}?`,
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        showLoaderOnConfirm: true,
      })
          .then(async (result) => {
            if (result.isConfirmed) {
              try {
                await this.$api.delete(`/api/roles/${row.id}`)

                this.loadItems()

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Success',
                    icon: 'BellIcon',
                    variant: 'success',
                    text: 'Successfully Deleted',
                  },
                })
              } catch (error) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: error?.response?.data?.message,
                  },
                })
              }
            }
          })
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer)
        this.delayTimer = null
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems()
      }, 1000)
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    async loadItems() {
      try {
        const resDivisions = await this.$api.get('api/roles?include=users', {
          params: {
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          },
        })

        const data = resDivisions?.data?.data
        const meta = resDivisions?.data?.meta

        this.totalRecords = meta?.pagination?.total
        this.rows = data
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },
    validationForm: async function () {
      this.$refs.rolesValidation.validate()
          .then(async (success) => {
            if (success) {
              try {
                if (this.modelType == 'editModel') {
                  this.isRoleFormSubmitLoading = true
                  await this.$api.put(
                      `/api/roles/${this.roleId}`,

                      {
                        name: this.name,
                        // priority: this.priority,
                      }
                  )
                  this.isRoleFormSubmitLoading = false
                  this.hiddenModal()

                  this.loadItems()

                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Success',
                      icon: 'BellIcon',
                      variant: 'success',
                      text: 'Successfully Updated',
                    },
                  })
                } else {
                  this.isRoleFormSubmitLoading = true
                  await this.$api.post(
                      '/api/roles',

                      {
                        name: this.name,
                        // priority: this.priority,
                      }
                  )
                  this.isRoleFormSubmitLoading = false
                  this.hiddenModal()

                  this.loadItems()

                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Success',
                      icon: 'BellIcon',
                      variant: 'success',
                      text: 'Successfully Created',
                    },
                  })
                }
              } catch (error) {
                this.isRoleFormSubmitLoading = false
                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: error?.response?.data?.message,
                    },
                  })
                }

                if (error?.response?.data?.errors) {
                  this.$refs.rolesValidation.setErrors(
                      error?.response?.data?.errors
                  )
                }
              }
            }
          })
    },

    roleCopyFormSubmit: async function () {
      this.$refs.roleCopyValidation.validate()
          .then(async (success) => {
            if (success) {
              try {
                this.isRoleCopySubmitLoading = true
                await this.$api.post(
                    '/api/roles/copy',

                    {
                      role_id: this.roleId,
                      name: this.roleCopyName,
                    }
                )
                this.isRoleCopySubmitLoading = false
                this.roleCopyHiddenModal()

                this.loadItems()

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Success',
                    icon: 'BellIcon',
                    variant: 'success',
                    text: 'Successfully Copied',
                  },
                })
              } catch (error) {
                this.isRoleCopySubmitLoading = false
                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: error?.response?.data?.message,
                    },
                  })
                }

                if (error?.response?.data?.errors) {
                  this.$refs.roleCopyValidation.setErrors(
                      error?.response?.data?.errors
                  )
                }
              }
            }
          })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
